// src/axiosConfig.js
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://ast-hints.cs.uct.ac.za/api/api/',
    headers: {
        'Content-Type': 'application/json',
    },
});

export default axiosInstance;
