import React, { useState } from "react";
import axios from "./axiosConfig";
import CodeEditor from "@uiw/react-textarea-code-editor";
import styled from "styled-components";
import { useEffect } from "react";

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #212124;
  color: #e0e0e0;
  min-height: 100vh;
`;

const Title = styled.h1`
  text-align: center;
  color: #ffffff;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormGroup = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FormItem = styled.div`
  margin-right: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #ffffff;
`;

const StyledCodeEditor = styled(CodeEditor)`
  font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono,
    Menlo, monospace;
  width: 400px;
  height: 300px;
  border-radius: 8px;
  border: 1px solid #333;
  background-color: #1e1e1e;
  color: #ffffff;
`;

const Input = styled.input`
  width: 300px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #333;
  background-color: #1e1e1e;
  color: #ffffff;
`;

const Select = styled.select`
  width: 80px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #333;
  background-color: #1e1e1e;
  color: #ffffff;
`;

const SubmitButton = styled.button`
  padding: 10px 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1em;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Loading = styled.div`
  flex: 1;
  font-size: 1.5em;
  padding: 15px;
  background-color: #333;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
`;

const Hint = styled.div`
  flex: 1;
  font-size: 1.5em;
  padding: 15px;
  background-color: #333;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
`;

function StudentForm() {
  const [studentCode, setStudentCode] = useState("");
  const [studentNumber, setStudentNumber] = useState("");
  const [studentGroup, setStudentGroup] = useState("A");
  const [hint, setHint] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lastSubmitTime, setLastSubmitTime] = useState(null);
  const [hintCount, setHintCount] = useState(0);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    let timer;
    if (cooldownTime > 0) {
        timer = setInterval(() => {
            setCooldownTime((prevTime) => prevTime - 1);
        }, 1000);
    } else {
        setIsButtonDisabled(false);
    }
    return () => clearInterval(timer);
}, [cooldownTime]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check if any inputs are empty
    if (!studentNumber) {
      alert("Please enter your student number.");
      setIsLoading(false);
      return;
    }
    if (!studentCode) {
      alert("You cannot submit empty code. Good try though!");
      setIsLoading(false);
      return;
    }

    // Check if the cooldown period has passed
    const now = new Date();
    if (lastSubmitTime && now - lastSubmitTime < 3 * 60 * 1000) {
      // 3 minutes in milliseconds
      alert("You can only submit once every 3 minutes.");
      setIsLoading(false);
      return;
    }

    // Check if the hint limit has been reached
    if (hintCount >= 3) {
      alert("You have reached the limit of 3 hints.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post("/hint-requests/", {
        student_code: studentCode,
        student_number: studentNumber,
        student_group: studentGroup,
      });

      // Update the last submit time and hint count
      setLastSubmitTime(now);
      setHintCount(hintCount + 1);

      setCooldownTime(3 * 60); // 3 minutes in seconds
      setIsButtonDisabled(true);

      pollHintResponse(response.data.id);
    } catch (error) {
      console.error("Error submitting hint request:", error);
      alert(
        "There was an error submitting your request. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const pollHintResponse = (requestId) => {
    const intervalId = setInterval(async () => {
      const response = await axios.get(
        `/hint-responses/?hint_request=${requestId}`
      );
      if (response.data.length > 0) {
        setHint(response.data[0].hint);
        setIsLoading(false);
        clearInterval(intervalId);
      } else {
        setIsLoading(true);
      }
    }, 3000); // Poll every 3 seconds
  };

  return (
    <Container>
      <Title>Submit Your Code</Title>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <FormItem>
            <Label>Student Code:</Label>
            <StyledCodeEditor
              value={studentCode}
              language="py"
              placeholder="Paste your code here"
              data-color-mode="dark"
              onChange={(e) => setStudentCode(e.target.value)}
            />
          </FormItem>
        </FormGroup>
        <FormGroup>
          <FormItem>
            <Label>Student Number:</Label>
            <Input
              type="text"
              value={studentNumber}
              maxLength="9"
              placeholder="Enter your student number"
              onChange={(e) => setStudentNumber(e.target.value)}
            />
          </FormItem>
          <FormItem>
            <Label>Group:</Label>
            <Select
              value={studentGroup}
              onChange={(e) => setStudentGroup(e.target.value)}
            >
              <option value="A">A</option>
              <option value="B">B</option>
            </Select>
          </FormItem>
        </FormGroup>
        <SubmitButton type="submit" disabled={isButtonDisabled}>
    {isButtonDisabled ? `Wait ${cooldownTime}s` : "Submit"}
</SubmitButton>
      </Form>
      <div>
        {isLoading ? (
          <Loading>
            <p>Loading... Please wait for your hint.</p>
          </Loading>
        ) : hint ? (
          <Hint>
            <p>
              <strong>Hint:</strong> {hint}
            </p>
          </Hint>
        ) : (
          <Hint>
            <p>
              <strong>Hint:</strong> Submit your code to get a hint
            </p>
          </Hint>
        )}
      </div>
    </Container>
  );
}

export default StudentForm;
