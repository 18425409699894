import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StudentForm from './StudentForm';
import TutorList from './TutorList';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<StudentForm />} />
                <Route path="/tutor" element={<TutorList />} />
            </Routes>
        </Router>
    );
}

export default App;
