import React, { useEffect, useState } from 'react';
import axios from './axiosConfig';
import CodeEditor from "@uiw/react-textarea-code-editor";

function TutorList() {
    const [hintRequests, setHintRequests] = useState([]);
    const [hints, setHints] = useState({});

    const fetchRequests = async () => {
        const response = await axios.get('/hint-requests/?student_group=B');
        setHintRequests(response.data);
    };
    
    useEffect(() => {
        fetchRequests(); // Initial fetch
    
        const intervalId = setInterval(() => {
            fetchRequests();
        }, 10000); // Poll every 10 seconds
    
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    const handleResponse = async (id, hint) => {
        await axios.post(`/hint-responses/`, { hint_request: id, hint });
        fetchRequests(); // Refresh the list after sending the hint
    };

    const handleHintChange = (id, value) => {
        setHints(prevHints => ({ ...prevHints, [id]: value }));
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Pending Hint Requests:</h1>
            <ul style={styles.list}>
                {hintRequests.map(request => (
                    <div key={request.id} style={styles.card}>
                        <li style={styles.listItem}>
                            <p><strong>Student Number:</strong> {request.student_number}</p>
                            <p><strong>Student Code:</strong></p>
                            <CodeEditor
                                value={request.student_code}
                                language="py"
                                data-color-mode="dark"
                                readOnly={true}
                                style={styles.codeEditor}
                            />
                            <p><strong>Group:</strong> {request.student_group}</p>
                            <textarea
                                placeholder="Enter hint here"
                                value={hints[request.id] || ''}
                                onChange={(e) => handleHintChange(request.id, e.target.value)}
                                style={styles.textarea}
                            />
                        </li>
                        <button
                            onClick={() => handleResponse(request.id, hints[request.id] || '')}
                            style={styles.button}
                        >
                            Send Hint
                        </button>
                    </div>
                ))}
            </ul>
        </div>
    );
}

const styles = {
    container: {
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    header: {
        textAlign: 'center',
        color: '#333',
    },
    list: {
        listStyleType: 'none',
        padding: 0,
    },
    card: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        margin: '10px 0',
        backgroundColor: '#f9f9f9',
    },
    listItem: {
        marginBottom: '10px',
    },
    codeEditor: {
        fontFamily: 'ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace',
        width: '100%',
        height: '300px',
        borderRadius: '8px',
        border: '1px solid #333',
        backgroundColor: '#1e1e1e',
        color: '#ffffff',
    },
    textarea: {
        width: '100%',
        height: '100px',
        borderRadius: '8px',
        border: '1px solid #ddd',
        padding: '10px',
        fontFamily: 'Arial, sans-serif',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
    },
};

export default TutorList;